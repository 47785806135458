.topic__item {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-top: 30px;
    gap: 10px;
    //   margin-top: 30;
  }
  
  .topic__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex: 0.2;
  }
  
  $small: 600px;
  $medium: 900px;
  
  @media screen and (max-width: $small) {
    .topic__item {
      flex-direction: column;
    }
  
    .topic__buttons {
      gap: 30px;
    }
  }