/* Switch.css */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }
  
  .switch-checkbox {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .switch-label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .switch-label:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  .switch-checkbox:checked + .switch-label {
    background-color: #4caf50;
  }
  
  .switch-checkbox:checked + .switch-label:before {
    transform: translateX(20px);
  }
  